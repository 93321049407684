<template>
    <div class="bucket" id="__bucket">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>桶管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-alert v-if="userIsFrozen" class="alert" title="特别提醒：服务到期后三个月内为您保留数据，可以进行续费，超过三个月未续费，数据将会被自动销毁，请您及时续期恢复服务。"
                  type="warning" show-icon :closable="false"></el-alert>
        <el-alert v-else class="alert" title="统计桶合计数据，平均延迟1~2小时，不作为计量数据，仅作参考。" type="warning" show-icon
                  :closable="false"></el-alert>

        <div class="bucket-page">
            <div class="bucket-opts">
                <div >
                    <OptButton size="small" type="primary" class="_create" @click="createBucket" v-if="accountType === 'main'">创建</OptButton>
                    <OptButton size="small" icon="icon iconfont iconbianzu1711"
                               style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                               @click="loadData(true)"></OptButton>
                </div>
                <div style="display: flex;">
<!--                    <el-select-->
<!--                        v-model="regionId"-->
<!--                        placeholder="选择资源池"-->
<!--                        style="margin-right: 10px;"-->
<!--                        @change="wcRegionChange"-->
<!--                    >-->
<!--                        <el-option-->
<!--                            v-for="item in regionList"-->
<!--                            :value="item.regionId"-->
<!--                            :label="item.regionName"-->
<!--                            :key="item.regionId"/>-->
<!--                    </el-select>-->
                    <el-autocomplete
                        ref="autoComplete"
                        clearable
                        class="search-input"
                        v-model="keyword"
                        :fetch-suggestions="querySearch"
                        placeholder="桶名称"
                        @keyup.enter.native="searchBucket"
                        @select="handleSearchSelect"
                        @clear="searchBucket"
                    >
                        <el-button slot="append" icon="el-icon-search" @click="searchBucket"></el-button>
                    </el-autocomplete>
                </div>
            </div>
            <div v-loading="loadingData" class="table-container">
                <div class="suspended-info" v-show="showSUSPENDED">
                    <div class="suspended-info-title">您的资源包已到期</div>
                    <div class="suspended-info-desc"><span class="time">{{expirationTime}}</span>服务失效数据将会销毁，请在服务失效前续订</div>
                    <OptButton size="small" type="primary" class="_create" @click="gotoRenew">立即续订</OptButton>
                </div>
                <el-table ref="listTable" :data="tableData" style="width: 100%;">
                    <el-table-column label="桶名称" show-overflow-tooltip>
            <span :class="'bucket-name'" slot-scope="{ row }" @click="checkBucketFiles(row)">
              {{ row.name }}
            </span>
                    </el-table-column>
                    <el-table-column label="资源池" prop="regionName">
                    </el-table-column>
                    <el-table-column v-if="regionType !== 'GDAS'" prop="sizeStr" label="容量"></el-table-column>
                    <el-table-column prop="create" label="创建时间">
                        <template slot-scope="{row}">{{
                                row.create && moment(row.create).format('YYYY-MM-DD HH:mm:ss')
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="60px" v-if="accountType === 'main'">
                        <template slot-scope="{ $index, row }">
                            <OptLink
                                :underline="false"
                                class="op-link"
                                :style="!row.canDelete || row.userStatus !== 'NORMAL' || row.status === 'deleting' ? 'color: #d9d9d9;' : (IS_SSC ? 'color: #FA5050;' : '')"
                                @click="confirmDeleteOne($index, row)"
                                :title="(!row.canDelete ? '桶中有文件，无法删除' : (row.userStatus === 'NORMAL' ? '' : '该区域已冻结，无法删除'))"
                                :disabled="!row.canDelete || row.userStatus !== 'NORMAL' || row.status === 'deleting'">
                                删除{{ row.status === 'deleting' ? '中' : '' }}
                            </OptLink>
                        </template>
                    </el-table-column>
                </el-table>

                <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                       @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
            </div>
            <el-dialog title="创建桶" width="600px" :close-on-click-modal="false" custom-class="changeConfirm" :visible.sync="showCreate"
                       style="margin-top: 15vh;"
                       @closed="createDialogClose">
                <el-form :model="form" :rules="rules" ref="form" @validate="onValidate" @submit.native.prevent
                         label-width="80px" style="">
<!--                    <el-form-item label="资源池" prop="regionId" :rules="{required: true, message: '请选择资源池'}">-->
<!--                        <el-select style="width: 332px;" v-model="form.regionId" placeholder="请选择资源池"-->
<!--                                   no-data-text="无可用资源池，请购买资源包"-->
<!--                                   @change="() => { if(this.form.bucketName) {this.$refs.form.validateField('bucketName')} }">-->
<!--                            <el-option-->
<!--                                v-for="item in regionList.filter(i => i.userStatus==='NORMAL')"-->

<!--                                :value="item.regionId"-->
<!--                                :label="item.regionName"-->
<!--                                :key="item.regionId"/>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item label="桶名称" prop="bucketName">
                        <div class="bucket-name-input">
                            <el-input style="width: 332px;" v-model.trim="form.bucketName" maxlength="63"
                                      placeholder="请输入" >
                          <span slot="suffix" class="el-input__count">
                            <span class="el-input__count-inner">
                              {{ form.bucketName.length + "/63" }}
                            </span>
                          </span>
                                <i v-if="formValidate" slot="suffix" class="el-input__icon el-icon-success"
                                   style="color: #5DC560"></i>
                            </el-input>
                        </div>
                        <div v-if="formValidate == null || formValidate" class="tip-show">
                            1、桶名一旦创建不可更改<br/>
                            2、桶名仅支持小写英文字母、数字、中划线（-）<br/>
                            3、必须以小写字母或数字开头和结尾<br/>
                            4、长度3-63字符<br/>
                        </div>
                    </el-form-item>
                    <el-form-item label="配额" prop="quota">
                        <el-input-number :controls="false" :min="0" v-model="form.quota" style="width: 300px;" :precision="0">

                        </el-input-number>
                        GB
<!--                        <el-input type="text" v-model="form.quota" maxlength="10"-->
<!--                                  onkeyup="value=value==='0'?0:value.replace(/^(0+)|[^\d]+/g,'')">-->
<!--                            <template slot="append">GB</template>-->
<!--                        </el-input>-->
                        <div class="tip-show">容量配额请填写正整数，配额为0表示无限制。</div>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
                      <el-button size="small" @click="createDialogClose" :disabled="cancelButtonDisabled">取 消</el-button>
                      <el-button size="small" type="primary" :loading="createLoading" @click="doCreate">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import moment from 'moment'
import {getRegionList} from '@/utils/session'
import {getEnv} from "@/utils/env";
import storage from "@/utils/storage";

export default {
    name: "Bucket",
    data() {
        const valideBucket = async (rule, value, callback) => {
            const bucketName = value.replace(/(^\s*)|(\s*$)/g, "");
            if (!/^[a-z0-9\-]{1,63}$/.test(bucketName)) {
                callback(new Error('仅支持小写英文字母、数字、中划线（-）'))
            }
            if (!/^[a-z0-9]((.*)?[a-z0-9])?$/.test(bucketName)) {
                callback(new Error('必须以小写字母或数字开头和结尾'))
            }
            if (bucketName.length > 63 || bucketName.length < 3) {
                callback(new Error('字符长度在 3 到 63 个字符'))
            }
            const existsResult = await store.dispatch("bucket/exists", {
                bucketName, regionId: storage.get('REGION_ID')
            });
            if (existsResult) {
                callback(new Error("该桶名称已存在，或被其他用户占用"));
                return false;
            }

            return true;
        };

        return {
            regionType: '',
            allRegionList: [],
            regionList: [],
            regionId: '',

            form: {
                bucketName: "",
                regionId: '',
                quota: 0
            },
            rules: {
                bucketName: [
                    {
                        pattern: /^[a-z0-9\-]{1,63}$/,
                        message: '仅支持小写英文字母、数字、中划线（-）',
                        trigger: "change"
                    },
                    {// - -1 1- -11 11-
                        pattern: /^[a-z0-9]((.*)?[a-z0-9])?$/,  
                        message: '必须以小写字母或数字开头和结尾',
                        trigger: "change"
                    },
                    {
                        required: true,
                        min: 3,
                        max: 63,
                        message: "字符长度在 3 到 63 个字符",
                        trigger: "change"
                    },
                    {validator: valideBucket, trigger: "blur"},
                ],
            },
            loadingData: false,
            keyword: "",
            tableData: [],
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            showCreate: false,
            createLoading: false,
            formValidate: null,
            cancelButtonDisabled: false,
            showSUSPENDED: false,
            expirationTime: '',
            resourceId: ''
        };
    },
    created() {
        this.regionType = localStorage.getItem('regionType')
        // const {regionId} = this.$route.query;
        // if (regionId && regionId !== 'undefined') {
        //
        // }
        // this.getRegionList();
        this.regionId = storage.get('REGION_ID');
        this.loadData(true);
    },
    mounted() {
        this.$uploadTask.init({}, '__bucket');
        // this.$uploadTask.closeList()
        this.allRegionList = getRegionList();
    },
    computed: {
        ...mapGetters(["userIsFrozen", "bucketList", "currentPoint", 'accountType', 'accountId', 'regionId']),
    },
    methods: {

        gotoRenew () {
            window.open(getEnv().ssc + '/ehualu/ehualu_portal_console/prod/brs/renew/' + this.resourceId)
        },
        moment,
        getRegionList() {
            this.loadingData = true
            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = res.data || [];
                        if (!this.regionId) {
                            this.regionId = this.regionList[0]?.regionId
                            this.loadData(true);
                        }
                    }
                })
        },
        getBucketAuth () {
            if (this.accountType === 'main') {
                return
            }
            const {accountId, regionId, tableData} = this;
            const bucketList = tableData.map(i => i.name)
            this.$postJson('/auth/user/bucket/get', {accountId, bucketList, regionId})
            .then(res => {
                if (res.code === '200') {
                    if (res.data && res.data.length > 0) {
                        this.$uploadTask.showEntrance(true)
                    } else {
                        this.$uploadTask.showEntrance(false)
                    }
                }
            })
        },
        wcRegionChange(id) {
            this.regionId = id;
            let {userStatus, expirationTime, resourceId} = this.allRegionList.find(i => i.regionId === id)
            this.resourceId = resourceId
            if (userStatus === 'NORMAL') {
                this.showSUSPENDED = false
                this.pager.current = 1
                this.loadData(true);
            } else if (userStatus === 'SUSPENDED') {
                this.showSUSPENDED = true
                this.tableData = []
                this.expirationTime = moment(expirationTime).add(15, 'days').format('YYYY-MM-DD HH:mm:ss')
            }

        },


        onValidate(param, valid) {
            if (param === 'bucketName') {
                if (valid) {
                    this.formValidate = true;
                } else {
                    this.formValidate = false;
                }
            }
        },
        validInput() {
            this.$refs["form"].validate();
        },
        searchBucket() {
            this.$refs["autoComplete"].activated = true;
            this.pager.current = 1;
            this.loadData();
        },
        querySearch(keyword, callback) {
            if (keyword) {
                const list = this.bucketList
                    .filter((item) => item.name.indexOf(keyword) > -1 && this.regionId === item.regionId)
                    .map((item) => {
                        return {
                            ...item,
                            value: item.name + `(${item.regionName})`,
                            regionId: item.regionId,
                            userStatus: item.userStatus
                        };
                    });
                callback(list);
                return;
            }
            callback([]);
        },
        handleSearchSelect(item) {

            this.checkBucketFiles({...item, name: item.value, regionId: item.regionId});
        },
        loadData(init = false) {
            this.loadingData = true;
            const {
                pager: {current, size},
                keyword,
                regionId = ''
            } = this;
            this.$store.dispatch("bucket/getBucketList", {keyword, current, size, init, regionId}).then((res) => {
                const {list, total} = res;
                this.tableData = [...list];
                this.pager.total = total;
                this.loadingData = false;
                this.listByName();
                setTimeout(() => {
                    this.getBucketAuth()
                }, 1000)

            });
        },
        listByName() {
            const {tableData} = this;
            if (!tableData || tableData.length === 0) {
                return
            }
            let data = tableData.map(i => ({bucketName: i.name, regionId: i.regionId}))
            this.$postJson("/bucket/listByName", data)
                .then((res) => {
                    for (let i = 0; i < tableData.length; i++) {
                        this.$set(tableData[i], "sizeStr", res.data[i].sizeStr)
                        this.$set(tableData[i], "objects", res.data[i].objects)
                        this.$set(tableData[i], "size", res.data[i].size)
                        this.$set(tableData[i], "canDelete", res.data[i].canDelete)
                    }
                });
        },
        handleSizeChange() {
            this.loadData();
        },
        handleCurrentChange() {
            this.loadData();
        },
        checkBucketFiles(row) {
            let {name, regionId, userStatus} = row;
            if (userStatus === 'SUSPENDED') {
                this.$message.warning('该资源池服务到期，不支持该操作！')
                return
            }
            name = name.split('(')[0];
            localStorage.setItem('bucket_create', row.create)
            this.$router.push(`/bucket/file?bucket=${name}&regionId=${regionId}&userStatus=${userStatus}&parent=`);
        },
        createBucket() {
            this.showCreate = true;

        },
        createDialogClose() {
            this.showCreate = false;
            setTimeout(() => {
                this.formValidate = null;
                this.form.bucketName = "";
                this.form.quota = 0
                this.$refs["form"].clearValidate();
            }, 500)


        },
        async doCreate() {
            let {bucketName = "", regionId, quota} = this.form;
            bucketName = bucketName.replace(/(^\s*)|(\s*$)/g, "");
            this.createLoading = true;
            this.cancelButtonDisabled = true;
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.$store
                        .dispatch("bucket/create", {bucketName, regionId, quota})
                        .then(() => {
                            this.createLoading = false;
                            this.cancelButtonDisabled = false;
                            this.createDialogClose();
                            setTimeout(() => {
                                this.pager.current = 1;
                                this.loadData(true);
                            }, 0)
                        })
                        .catch((e) => {
                            this.createLoading = false;
                            this.cancelButtonDisabled = false;
                            this.createDialogClose();
                            this.$message.error(e.message || "创建失败，请重试");
                        });
                } else {
                    this.createLoading = false;
                    this.cancelButtonDisabled = false;
                }
            });
        },
        confirmDeleteOne(index, row) {
            const {tableData} = this;
            const list = this.$uploadTask.getFiles().filter(i => i.bucket === row.name).map(j => j.status);// bucket   status
            if (list.indexOf('1') > -1 || list.indexOf('2') > -1 || list.indexOf('0') > -1) {
                this.$message.warning("删除失败，请先取消桶内文件上传");
                return;
            }
            if (tableData.length <= index) {
                this.$message.error("删除失败");
                return;
            }
            if (row.size > 0) {
                this.$message.error("桶中有文件，不能删除");
                return;
            }
            this.$confirm("确定删除 桶" + tableData[index].name + " 吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: '',
                type: "warning",
                showClose: false,
            })
                .then(() => {
                    this.deleteOne(index, row);
                })
                .catch(() => {
                });
        },
        deleteOne(index, row) {
            const {tableData} = this;
            const {name} = tableData[index];
            this.loadingData = true;
            this.$store
                .dispatch("bucket/delete", {bucketName: name, regionId: row.regionId})
                .then((res) => {
                    this.loadingData = false;
                    this.$message.success("删除成功");
                    setTimeout(() => {
                        this.pager.current = 1;
                        this.loadData(true);
                    }, 0)
                })
                .catch((e) => {
                    this.loadingData = false;
                    console.error(e.message);
                    this.$message.error("删除失败," + e.message);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.bucket .el-breadcrumb__inner {
    font-size: 16px !important;
    color: #333 !important;
    font-weight: 500;

}



.bucket {
    margin: 16px 16px 10px;
    //height: calc(100% - 10px);
    padding: 0 26px;
    background: #fff;
    text-align: left;
    min-height: calc(100% - 36px);

    ::v-deep.changeConfirm {
        .el-dialog__header {
            //display: none;
            border: none;
            padding: 30px 30px 0;

            .el-dialog__title {
                font-size: 16px;
                color: rgba(0, 0, 0, .85);
                font-weight: 500;
            }

            .el-dialog__headerbtn {
                top: 30px;
                right: 30px;
            }
        }

        .el-dialog__body {
            padding: 30px 30px 0;
        }

        .el-form-item__label {
            font-size: 14px;
            color: #000;
            font-weight: 400;
        }
    }
}

.alert {
    margin: 0;
}

.bucket-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
}

.bucket-name {
    //color: #333333;
    /*color: #337dff;*/
    cursor: pointer;
}

.bucket-name:hover {
    color: #337dff;
}

.tip-show {
    display: block;
    font-size: 12px;
    color: #bbbbbb;
    letter-spacing: 0;
    line-height: 18px;
}

.tip-hide {
    display: none;
}

.table-container {
    position: relative;
    .suspended-info {
        position: absolute;
        top: 38px;
        left: 0;
        right: 0;
        background: #fff;
        height: 260px;
        z-index: 2;
        text-align: center;
        &-title {
            margin-top: 130px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
        }
        &-desc {
            font-size: 12px;
            font-weight: 400;
            color: #333;
            margin: 30px;
            .time {
                color: #E2757A;
            }
        }
    }
}
</style>
